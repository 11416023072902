import { config } from "config"
import useUser from "hooks/useUser"
import { useRouter } from "next/router"
import { useEffect } from "react"

import { IPage } from "data/contentData/api/content"

/**
 * Send custom variables to GTM
 * @param page page data model
 */
export function useGTMCustomVariables(page?: IPage) {
    const { asPath } = useRouter()
    const { user } = useUser()
    const { features, general } = config

    const articleGuid = page?.contentGuid ?? ""
    const templateType = page?.types?.join(".") ?? ""
    const userGuid = user?.guid ?? ""
    const parentGuid = user?.parentId ?? ""

    const latestProduct = user?.products[0]
    const activeProduct = Boolean(latestProduct)
    const subscriptionStart = latestProduct?.start ?? ""

    useEffect(() => {
        window.dataLayer.push({
            articleGuid,
            templateType,
            userGuid,
            parentGuid,
            activeProduct,
            subscriptionStart
        })
    }, [
        articleGuid,
        parentGuid,
        asPath,
        templateType,
        userGuid,
        activeProduct,
        subscriptionStart
    ])

    useEffect(() => {
        if (features.enableInternalTracking) {
            if (process.env.NODE_ENV === "production") {
                fetch(`${general.appHost}/api/usage/record`)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [asPath])
}
