import { config } from "config";
import { t } from "locales";
import Head from "next/head";
import { useRouter } from "next/router";
import { ReactNode } from "react";
import { IAuthor } from "data/contentData/api/content";
interface MetaProps {
  title: string;
  description?: string;
  type?: string;
  image?: {
    src: string;
    alt: string;
  };
  dateModified?: Date;
  isPublic?: boolean;
  datePublished?: Date;
  url?: string;
  authors?: IAuthor[];
  noIndex?: boolean;
  children?: ReactNode;
  metadata?: {
    title: string;
    description: string;
  };
}
const logo = `/${process.env.NEXT_PUBLIC_ID}/logo_white.svg`;
function Meta(props: MetaProps) {
  const {
    title,
    description,
    image,
    type = "website",
    url,
    dateModified,
    datePublished,
    authors,
    isPublic,
    children,
    noIndex,
    metadata
  } = props;
  const {
    asPath
  } = useRouter();
  const _url = `${config.general.appHost ?? ""}${url ?? asPath}`;
  const _description = metadata ? metadata.description : description;
  const _title = metadata ? metadata.title : `${title} - ${t["site-name"]}`;
  return <Head data-sentry-element="Head" data-sentry-component="Meta" data-sentry-source-file="Meta.tsx">
            <title>{`${_title}`}</title>

            {noIndex ? <meta name="robots" content="noindex" /> : null}

            <meta content={`${_title}`} name="og:title" data-sentry-element="meta" data-sentry-source-file="Meta.tsx" />

            <meta content={type} property="og:type" data-sentry-element="meta" data-sentry-source-file="Meta.tsx" />

            <meta content={removeTrailingSlashes(_url)} property="og:url" data-sentry-element="meta" data-sentry-source-file="Meta.tsx" />
            <link rel="canonical" href={toCanonicalUrl(_url)} />

            {_description && <meta content={_description} name="og:description" />}
            {_description && <meta content={_description} name="description" />}

            {image?.src && <meta content={image.src} name="og:image" />}

            <meta content={image?.src ? "summary_large_image" : "summary"} name="twitter:card" data-sentry-element="meta" data-sentry-source-file="Meta.tsx" />

            {image?.alt && <meta content={image.alt} name="twitter:image:alt" />}

            {type === "article" ? <script dangerouslySetInnerHTML={{
      __html: JSON.stringify(removeFalsy({
        "@context": "http://schema.org",
        "@type": "Article",
        author: authors?.map(author => removeFalsy({
          "@type": "Person",
          affiliation: author.organization,
          jobTitle: author.title,
          name: author.name
        })),
        dateModified,
        datePublished,
        description,
        hasPart: !isPublic && [{
          "@type": "WebPageElement",
          cssSelector: ".paywall",
          isAccessibleForFree: "False"
        }, {
          "@type": "WebPageElement",
          cssSelector: ".summary",
          isAccessibleForFree: "True"
        }],
        headline: title,
        image: logo,
        isAccessibleForFree: !isPublic && "False",
        mainEntityOfPage: {
          "@id": removeTrailingSlashes(_url),
          "@type": "WebPage"
        },
        publisher: {
          "@type": "Organization",
          logo: {
            "@type": "ImageObject",
            url: logo
          },
          name: t["site-name"]
        }
      }))
    }} type="application/ld+json" /> : null}
            {children}
        </Head>;
}
export default Meta;
function removeFalsy(obj: Record<string, unknown>) {
  const newObj: Record<string, unknown> = {};
  Object.keys(obj).forEach(prop => {
    if (obj[prop]) {
      newObj[prop] = obj[prop];
    }
  });
  return newObj;
}

/**
 * remove trailing slashes
 * @param pageUrl page url
 * @returns return page url without trailing slashes
 */
function removeTrailingSlashes(pageUrl: string) {
  if (pageUrl?.endsWith("/")) return pageUrl.substring(0, pageUrl.length - 1);
  return pageUrl;
}

/**
 * Remove querystring and trailing slashes from URL
 * @param url the URL to convert to canonical
 * @returns URL without querystring and trailing slashes
 */
function toCanonicalUrl(url: string) {
  const _url = new URL(url);
  _url.search = "";
  return removeTrailingSlashes(_url.toString());
}