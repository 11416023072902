import { captureMessage } from "@sentry/nextjs";
import { Icon } from "components/Icon";
import Layout from "components/Layout";
import Meta from "components/Meta";
import { config } from "config";
import { useGTMCustomVariables } from "hooks/useGTMCustomVariables";
import { t } from "locales";
import { NextPage } from "next/app";
import { useRouter } from "next/router";
import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
function querySuggestion(queryString: string | string[] | undefined): string {
  const qs = Array.isArray(queryString) ? queryString.slice(-1)[0] : queryString;
  if (!qs) {
    return "";
  }
  return qs.replace(/-\d+.html/, "").replace(/\-/g, " ");
}
const NotFoundPage: NextPage = () => {
  const [q, setQ] = useState("");
  const router = useRouter();
  const {
    page
  } = router.query;
  const suggestion = querySuggestion(page);
  useGTMCustomVariables();
  useEffect(() => {
    if (suggestion) {
      setQ(suggestion);
    }
  }, [suggestion]);
  useEffect(() => {
    captureMessage("Page not found", {
      level: "warning"
    });
  }, []);
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setQ(e.target.value);
  };
  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    router.push({
      pathname: config.rewrites["/search"],
      query: {
        q: q.trim()
      }
    });
  };
  return <Layout isSimple={true} data-sentry-element="Layout" data-sentry-component="NotFoundPage" data-sentry-source-file="404.tsx">
            <StyledGridContainer container alignItems="center" direction="column" justifyContent="center" spacing={4} data-sentry-element="StyledGridContainer" data-sentry-source-file="404.tsx">
                <Meta description={t["404"]["subtitle"]} title={t.layouts.error} data-sentry-element="Meta" data-sentry-source-file="404.tsx">
                    <meta content="noindex" name="robots" data-sentry-element="meta" data-sentry-source-file="404.tsx" />
                </Meta>
                <Grid item data-sentry-element="Grid" data-sentry-source-file="404.tsx">
                    <Typography variant="h1" data-sentry-element="Typography" data-sentry-source-file="404.tsx">{t["404"]["title"]}</Typography>
                </Grid>
                <Grid item data-sentry-element="Grid" data-sentry-source-file="404.tsx">
                    <Typography variant="h2" data-sentry-element="Typography" data-sentry-source-file="404.tsx">{t["404"]["subtitle"]}</Typography>
                </Grid>
                <Grid item data-sentry-element="Grid" data-sentry-source-file="404.tsx">
                    <Typography variant="body2" component="span" dangerouslySetInnerHTML={{
          __html: t["404"]["description"]
        }} data-sentry-element="Typography" data-sentry-source-file="404.tsx" />
                </Grid>
                <Grid item container component="form" onSubmit={handleSubmit} data-sentry-element="Grid" data-sentry-source-file="404.tsx">
                    <StyledTextField placeholder={t.search.search} inputProps={{
          "aria-label": t.search.search
        }} onChange={handleChange} value={q} data-sentry-element="StyledTextField" data-sentry-source-file="404.tsx" />
                    <StyledIconButton type="submit" aria-label="Search" data-sentry-element="StyledIconButton" data-sentry-source-file="404.tsx">
                        <Icon name="search" data-sentry-element="Icon" data-sentry-source-file="404.tsx" />
                    </StyledIconButton>
                </Grid>
                <Grid item data-sentry-element="Grid" data-sentry-source-file="404.tsx">
                    <Link href="/" data-sentry-element="Link" data-sentry-source-file="404.tsx">{t["go-to-homepage"]}</Link>
                </Grid>
            </StyledGridContainer>
        </Layout>;
};
const StyledGridContainer = styled(Grid)(() => ({
  margin: "0 auto",
  maxWidth: "80vw",
  minHeight: "60vh"
}));
const StyledTextField = styled(TextField)(({
  theme
}) => ({
  flex: 1,
  marginLeft: theme.spacing(1)
}));
const StyledIconButton = styled(IconButton)(({
  theme
}) => ({
  padding: theme.spacing(2),
  marginLeft: theme.spacing(1)
}));
export default NotFoundPage;